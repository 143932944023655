.dropdown-menu {
    li {
        cursor: pointer;
    }
}

.color-n {
    color: #7B39EB;
}

.color-1-n {
    color: #DE0068;
}

.color-2-n {
    color: #FCA616;
}

.hidden {
    display: none !important;
}

.app-wrapper {
    position: relative;
    height: 100vh;
}

.loader-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.date-picker-footer {
    display: none;
}

.sidebar-header {
    @media (min-width: 992px) {
        justify-content: center;
    }
}

.sidebar-header {
    padding: 0 15px;
}

.body {
    position: relative;
}

@media (min-width: 992px) {

    .sidebar-narrow-unfoldable:not(:hover) .sidebar-brand-full,
    .sidebar-narrow .sidebar-brand-full {
        display: initial;
    }
}

@media (min-width: 992px) {
    .header-toggler {
        display: none;
    }
}


//Test
// .rdw-editor-wrapper{
//     border: var(--cui-border-width) solid var(--cui-border-color);
//     border-radius: var(--cui-border-radius);

// }

// .rdw-editor-toolbar{
//     background-color: var(--cui-body-bg) !important;
//     border: 0px !important;
//     border-bottom: var(--cui-border-width) solid var(--cui-border-color) !important; 
//     margin-bottom: 0 !important;
// }

// .public-DraftStyleDefault-block {
//     margin: 0.8em 0.8em !important;
// }

// .rdw-editor-main{
//     background-color: var(--cui-body-bg) !important;
// }

.quill {
    border: var(--cui-border-width) solid var(--cui-border-color) !important;
}

.quill {

    border-radius: var(--cui-border-radius) !important;

    .ql-toolbar.ql-snow {
        border: none;
        border-bottom: var(--cui-border-width) solid var(--cui-border-color) !important;
        // border-radius: var(--cui-border-radius) var(--cui-border-radius) 0 0;
    }

    .ql-container.ql-snow {
        border: none;
    }

}

.quill:focus-within {
    border-color: #acabeb !important;
    box-shadow: 0 0 0 0.25rem rgba(88, 86, 214, 0.25);
    transition: border-color 0.3s ease;
}

.react-easy-crop-preview-image {
    display: none;
}