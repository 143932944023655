$custom-color: #9e2a39;

:root {
    // --cui-primary: #9e2a39;
    // --cui-primary-rgb: 229, 83, 83 !important;
    --cui-link-color-rgb: 158, 42, 57 !important;
    --cui-link-hover-color-rgb: 158, 42, 57 !important;
}

.light-theme,
[data-coreui-theme=light] {

    .sidebar-nav .nav-link.active,
    .sidebar-nav .nav-link.active .nav-icon {
        background: $custom-color !important;
        color: #FFFFFF !important;
    }

}

.dark-theme,
[data-coreui-theme=dark] {

    --cui-body-bg: #1F1F20 !important;
    --cui-dark-bg-subtle: #262627 !important;
    --cui-border-color: #373738 !important;

    .swal2-popup {
        background-color: #333 !important;
        color: #fff !important;
    }

    .swal2-styled.swal2-confirm,
    .swal2-styled.swal2-cancel {
        color: #fff !important;
    }

    .swal2-container {
        background-color: rgba(0, 0, 0, 0.6) !important;
    }

}

.sidebar-nav .nav-link.active {
    background: $custom-color !important;
}